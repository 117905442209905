const statistics = {
  module: 'Statistics',
  tab: {
    solvers: 'Solvers Statistic',
    website: 'Website Statistic',
  },
  form: {
    start: 'Start Date',
    end: 'End Date',
    to: 'to',
  },
  pie: {
    title: 'Job Status',
  },
};
export default statistics;

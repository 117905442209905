const statistics = {
  module: '统计',
  tab: {
    solvers: '任务统计',
    website: '网站统计',
  },
  form: {
    start: '开始日期',
    end: '结束日期',
    to: '至',
  },
  pie: {
    title: '任务状态分布',
  },
};
export default statistics;
